<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
      >
        <b-tabs
          pills
          vertical
        >
          <b-tab
            v-for="(item,index) in serviceData"
            :key="index"
            :title="`${index+1}. ` + showFromCurrentLanguage(item.name)+ ' ('+$t(item.serviceId.serviceType.replace('_',' ').toUpperCase())+')'"

            @click="selectTypeId = item._id"
          >
            <Content
              v-if="selectTypeId === item._id"
              :service-type-id="item._id"
              :service-type="item.serviceId.serviceType.replace('_', '-')"
            />
          </b-tab>
        </b-tabs>

      </b-card>
      <!-- <b-row>
        <b-col
          v-for="(item,index) in serviceData"
          :key="index"
          md="6"
          lg="4"
        >
          <b-card
            :title="showFromCurrentLanguage(item.name)+ ' ('+$t(item.serviceId.serviceType.replace('_',' ').toUpperCase())+')'"
            class="text-center"
          >
            <b-card-text>
              {{ $t('Open Profile') }}
            </b-card-text>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="openPackage(item)"
            >
              {{ $t('Open') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row> -->
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTabs, BTab,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BOverlay,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import storeModule from '@/store/services/hero/serviceType'
import debounce from 'debounce'
import Content from './component/Content.vue'

const STORE_MODULE_NAME = 'serviceType'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    BOverlay,
    BCardText,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Content,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      selectTypeId: null,
      perPageOptions,
    }
  },
  computed: {
    serviceData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: 0,
      pageSize: 0,
      searchQuery: '',
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        const {data} = result.data
        if(data.length>0){
          this.selectTypeId = data[0]._id
        }
        console.log('fetch Success : ', data)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    openPackage(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to enter')} ${this.$t('profile')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/hero/partner/type/${data._id}/${data.serviceId.serviceType.replace('_', '-')}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },

    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
